header {
  position: fixed;
  z-index: 1;
}

nav {
  width: 100%;
  height: 60px;
  background-color: #c4ced5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  gap: 10px;

}

.nav-list {
  display: flex;
  justify-content: space-between;
  width: 350px;
}

.nav-link {
  text-decoration: none;
  color: #797979;
  font-weight: bold;
  font-size: 1.1rem;
}

.nav-transparent {
  background: none;
  height: 60px;
  background-color: #c4ced5a0;
  box-shadow: 0px 0px 10px 10px #c4ced5a0;
}

.hamburger {
  display: hidden;
}

.bar {
  display: hidden;
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}
.hamburger.active .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}
.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.nav-list.active {
  right: 0;
  top: 0;
}

@media (max-width: 600px) {

  nav {
    padding: 0 5px;
  }
  nav h1 {
    width: 250px;
  }
  .nav-list {
    position: absolute;
    /* z-index: 99999; */
    right: -100%;
    background-color: white;
    flex-direction: column;
    width: 150px;
    border-radius: 0 0 0 5px;
    gap: 10px;
    padding: 20px 10px;
    /* height: 100vh; */
    justify-content: flex-start;
    transition: .3s ease;
    /* z-index: 1000; */
  }
  .hamburger {
    display: block;
    padding: 0.5rem;
    position: fixed;
    top: 0;
    right: 0;
    margin: 3px 3px 0 0;
    color: black;
    z-index: 99;
    /* border: 2px solid white; */
  }
  .bar {
    display: block;
  
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: black;
  }
}




