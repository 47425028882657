.slider {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, 50%);
    border-radius: 20%;
    
}

.slider button {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    padding: 6px;
    border: none;
    cursor: pointer;
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: #c4ced5;
    outline: none;
}

.slider button:hover {
    background-color: #9facb5;
}

.slider-active {
    left: .4%;
}

@media (max-width: 1200px) {
    .slider {
        display: none
    }
}

