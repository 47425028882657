* {
  /* border: 2px solid white; */
}

.projects-container {
  /* padding-top: 70px; */
  width: 100%;
  height: 100%;
  padding-top: 60px;
  /* overflow-y: scroll; */
  /* position: relative; */
  background-color: #191919;
  /* background: linear-gradient(45deg, #191919 10%, #);   */
  display: flex;
}

.project-list-container {
  width: 20%;
  min-width: 250px;
  max-width: 300px;
  height: 100vh;
  overflow-y: scroll;
  /* position: absolute; */
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  border-right: 2px solid white;
  position: relative;
  transition: .03s linear;
  display: flex;
  flex-direction: column;
}

/* .project-list-container::-webkit-scrollbar {
  display: none;
} */

.project-card {
  cursor: pointer;
  /* text-align: center; */
  /* margin: 0px 10px; */
  border-bottom: 2px solid white;

  background-color: none;
  color: white;
}

/* #project-0 {
  background-color: white;
  color: black;
} */



.project-card button {
  /* color: inherit;
    background-color: inherit; */
  height: 100%;
  width: 100%;
  padding: 20px;
  outline-color: transparent;
  border: none;
  background: none;
  font-weight: bold;
  font-size: 1rem;
  color: white;
  cursor: pointer;

}

.project-card button:focus {
  z-index: 99;
}

/* .project-card button:focus {
  background-color: white;
  color: black;
} */

.project-img-container {
  width: 100%;
}

.project-img-container img {
  width: 100%;
}

.project-detail {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  position: relative;
  padding: 5% 1% 1% 2%;
  color: white;
  gap: 30px;
}

.detail-one {
  width: 60%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  /* justify-content: center; */
  align-items: center;
}

.detail-two {
  width: 40%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.detail-img-container {
  width: 100%;
}
.detail-img-container img {
  width: 100%;
  height: 100%;
}

.detail-links {
  width: 100%;
  display: flex;
  gap: 2px;
}
.detail-links button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 1%;
  gap: 10px;
  border: none;
  border-top: none;
  outline-color: transparent;
  background-color: #c4ced5;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  color: black;

}

.detail-links button:hover {
  background-color: #9facb5;

}

.detail-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  font-size: 1.5rem;
    /* padding-left: 2%; */
  /* border: 2px solid white; */
  list-style: none;
}

.list-hidden {
  position: absolute;
  height: 100%;
  transform: translateX(-100%)
}

.detail-width-change {
  width: 100%;
}

@media (min-width: 2000px) {
  .project-detail {
    justify-content: center;
    align-items: flex-start;
    padding-top: 1%;
  }
}

@media (min-width: 600px) {
  .project-list-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
    
  }
  
  .project-list-container::-webkit-scrollbar {
    display: none;
  }
}


@media (max-width: 1200px) {
  .projects-container {

  }

  .projects-container {
    /* padding-top: 70px; */
    width: 100%;
    height: 100%;
    /* overflow-y: scroll; */
    /* position: relative; */
    background-color: #191919;
    display: flex;

    flex-direction: column;
  }

  .project-list-container {
    width: 100%;
    height: 130px;
    min-width: 250px;
    max-width: 100%;
    /* max-height: calc(100%); */
    /* position: relative; */
    /* z-index: 99; */
    flex-direction: row;
    align-items: flex-start;
    /* -ms-overflow-style: visible;
    scrollbar-width: default; */
    overflow-x: scroll;
    overflow-y: hidden;
  }

  /* .project-list-container::-webkit-scrollbar {
    display: block;
    background-color: grey;
    color: white;
  } */

  .project-card {
    /* transform: rotate(-90deg); */
    width: 100%;
    min-width: 100px;
    font-size: .5rem;
    padding: 0;
    height: 100%;
    border-right: 2px solid white;
  }

  .project-card button {
    font-size: .8rem;
    padding: 0 5px;
    height: 100%;
  }
  
.project-detail {
  flex-direction: column;
  /* padding-top: 60px; */
  overflow-y: scroll;
  padding: 5% 3% 5%;
}

  .detail-one {
    width: 100%;
  }
  
  .detail-two {
    width: 100%;
  }

  header {
    height: 60px;
  }
  nav {
    height: 60px;
  }
}